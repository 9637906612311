import React from "react"

import Layout from "../components/Layout"
import Policies from "../components/Policies"
import SEO from "../components/seo"

const PoliciesPage = ({ location, history }) => (
  <Layout location={location} history={history}>
    <SEO title="Policies" />
    <Policies />
  </Layout>
)

export default PoliciesPage
