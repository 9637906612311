import React from "react"
import ImageSection from "../ImageSection"

import styles from "./Policies.module.css"

const PoliciesView = () => {
  return (
    <div>
      <ImageSection />
      <section className={styles.section}>
        <h1>POLICIES</h1>
        <p>
          All lenders and lending partners are required to abide by certain
          laws. Once you sign your loan agreement and begin the repayment
          process, you are protected by these laws and other regulations.
          Questions regarding these laws should be directed to a legal
          professional. The federal government has created regulations for
          lenders to protect against unfair practices. According to the Truth in
          Lending Act, details of all loan agreements must be provided in
          written form and must display the exact rates, fees, late fees,
          rollover charges, and all other details of the loan. The terms offered
          by the lender or lending partner must conform also to all applicable
          state regulations based on your state of residence. Under the
          Dodd-Frank Wall Street Reform Act, every lender must practice ‘fair
          lending.’ Lenders cannot discriminate against anyone, but must offer
          equitable, fair access to loans. This act also gives the Consumer
          Financial Protection Bureau the power to enforce these regulations.
          You are also protected by the Fair Debt Collection Practice Act
          (FDCPA) which requires lenders to follow certain practices. This act
          prohibits actions including, but not limited to:
        </p>
        <p>● Unpermitted threats of legal action and jail time</p>
        <p>● Providing false information about themselves, you, or your debt</p>
        <p>● The use of abusive and/or threatening language </p>
        <p>
          ● No phone contact before 8AM or after 9PM, unless you agree otherwise
        </p>
      </section>
    </div>
  )
}

export default PoliciesView
